import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) { return (_pushScopeId("data-v-bcf91126"), n = n(), _popScopeId(), n); };
var _hoisted_1 = {
    id: "kt_content_container",
    class: "w-100",
    style: { "position": "fixed" }
};
var _hoisted_2 = { class: "sticky-top" };
var _hoisted_3 = {
    class: "d-md-flex container-fluid",
    style: { "background-color": "#fbfbfb" }
};
var _hoisted_4 = { class: "p-xs-0 p-sm-0 first_block flex_10" };
var _hoisted_5 = { class: "main_container flex_2" };
var _hoisted_6 = { style: { "z-index": "9999" } };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_KTLoader = _resolveComponent("KTLoader");
    var _component_base_vertical_menu = _resolveComponent("base-vertical-menu");
    var _component_base_horizontal_menu = _resolveComponent("base-horizontal-menu");
    var _component_router_view = _resolveComponent("router-view");
    var _component_base_loading_logo = _resolveComponent("base-loading-logo");
    var _component_KTFooter = _resolveComponent("KTFooter");
    var _component_KTSidebar = _resolveComponent("KTSidebar");
    var _component_KTUserMenu = _resolveComponent("KTUserMenu");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        (_ctx.loaderEnabled)
            ? (_openBlock(), _createBlock(_component_KTLoader, {
                key: 0,
                logo: _ctx.loaderLogo
            }, null, 8, ["logo"]))
            : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.navbar)))
            ]),
            _createElementVNode("div", _hoisted_3, [
                (_ctx.showCategoryMenu)
                    ? (_openBlock(), _createBlock(_component_base_vertical_menu, {
                        key: 0,
                        class: "flex_1 me-3"
                    }))
                    : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                        (_ctx.showCategoryMenu)
                            ? (_openBlock(), _createBlock(_component_base_horizontal_menu, {
                                key: 0,
                                class: "w-100"
                            }))
                            : _createCommentVNode("", true),
                        _createVNode(_component_router_view, { class: "main_div" }),
                        _createElementVNode("div", _hoisted_6, [
                            _createVNode(_Transition, {
                                name: "fade",
                                mode: "out-in"
                            }, {
                                default: _withCtx(function () { return [
                                    (_ctx.store.getters.globalLoading)
                                        ? (_openBlock(), _createBlock(_component_base_loading_logo, { key: 0 }))
                                        : _createCommentVNode("", true)
                                ]; }),
                                _: 1
                            })
                        ])
                    ])
                ]),
                (_ctx.showSidebar)
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.sidebar), {
                        key: 1,
                        class: "flex_1 ms-3"
                    }))
                    : _createCommentVNode("", true)
            ]),
            _createVNode(_component_KTFooter, { class: "fixed-bottom bg-white" })
        ]),
        (_ctx.displaySidebar)
            ? (_openBlock(), _createBlock(_component_KTSidebar, { key: 1 }))
            : _createCommentVNode("", true),
        _createVNode(_component_KTUserMenu)
    ], 64));
}
