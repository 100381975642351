import { defineComponent, onMounted, ref } from "vue";
import { getCurrentStore } from "@/core/helpers/custom";
export default defineComponent({
    name: "BaseLoadingLogo",
    setup: function () {
        var currentStore = ref();
        onMounted(function () {
            getCurrentStore().then(function (res) {
                currentStore.value = res;
            });
        });
        return {
            currentStore: currentStore,
        };
    },
});
