import { defineComponent } from "vue";
export default defineComponent({
    name: "BaseSymbol",
    props: {
        src: {
            type: String,
            required: true,
        },
        height: {
            type: String,
        },
        width: {
            type: String,
        },
        isRounded: {
            type: Boolean,
            default: false,
        },
    },
});
