import ApiService from "@/core/services/ApiService";

export const newAddress = async (payload) => {
  return await ApiService.post(`/addresses`, payload);
};

export const getAddress = async (params) => {
  return await ApiService.query(`/addresses`, {
    params,
  });
};

export const showAddress = async ({ addressId }) => {
  return await ApiService.get(`/addresses/${addressId}`);
};

export const updateAddress = async (payload) => {
  return await ApiService.put(`/addresses/${payload.id}`, payload);
};

export const deleteAddress = async (id) => {
  return await ApiService.delete(`/addresses/${id}`);
};

export const multipleDeleteAddress = async (payload) => {
  return await ApiService.post(`/addresses/multi-delete`, payload);
};
