import ApiService from "@/core/services/ApiService";

export const getCategoryList = async () => {
  const response = await ApiService.get(`/categories`);
  return response;
};

export const getProductsByCategory = async ({ slug }) => {
  const response = await ApiService.get(`/categories/${slug}`);
  return response.data;
};
