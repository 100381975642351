import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) { return (_pushScopeId("data-v-2d976f0d"), n = n(), _popScopeId(), n); };
var _hoisted_1 = {
    key: "nohara_loading",
    class: "nohara_loading"
};
var _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("img", {
            class: "logo",
            src: require('@/assets/image/adjust_qty_loading.gif')
        }, null, 8, _hoisted_2)
    ]));
}
