import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["src", "width", "height"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _a;
    return (_openBlock(), _createElementBlock("span", {
        class: _normalizeClass(["m-0", [!_ctx.height && !_ctx.width && 'symbol symbol-20px']])
    }, [
        _createElementVNode("img", {
            class: _normalizeClass(["rounded-1", [_ctx.isRounded && 'rounded-circle']]),
            src: (_a = _ctx.src) !== null && _a !== void 0 ? _a : '',
            alt: "metronic",
            width: _ctx.width,
            height: _ctx.height
        }, null, 10, _hoisted_1)
    ], 2));
}
