// import axiosInstance from "@/core/services/ApiAxios";
import ApiService from "@/core/services/ApiService";

export const wishlist = async (product_id) => {
  const response = await ApiService.post(`/wishlists`, product_id);
  return response;
};

export const getWishlist = async (params) => {
  const response = await ApiService.query(`/wishlists`, {
    params: params,
  });
  return response;
};

export const removeWishlist = async (product_id) => {
  const response = await ApiService.post(`/wishlists/remove`, product_id);
  return response;
};

export const emptyWishlist = async () => {
  const response = await ApiService.post(`/wishlists/empty`, {});
  return response;
};
