import { __awaiter, __generator } from "tslib";
import { defineComponent, computed, onMounted, watch, nextTick, ref, } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import HtmlClass from "@/core/services/LayoutService";
import KTUserMenu from "@/layout/header/partials/ActivityDrawer.vue";
import { MenuComponent } from "@/assets/ts/components";
import { reinitializeComponents } from "@/core/plugins/keenthemes";
import { removeModalBackdrop } from "@/core/helpers/dom";
import { Actions } from "@/store/enums/StoreEnums";
import KTLoader from "@/components/Loader.vue";
import BaseVegetableNavBar from "@/components/dynamic/vegetable/BaseVegetableNavbar.vue";
import BaseClothNavBar from "@/components/dynamic/cloth/BaseClothNavBar.vue";
import KTFooter from "@/layout/footer/Footer.vue";
import KTSidebar from "@/layout/sidebar/Sidebar.vue";
import BaseVegetableSidebar from "@/components/dynamic/vegetable/BaseVegetableSidebar.vue";
import { toolbarDisplay, loaderEnabled, contentWidthFluid, loaderLogo, asideEnabled, subheaderDisplay, displaySidebar, } from "@/core/helpers/config";
import { getCartList, screenWidth } from "@/core/helpers/custom";
import { emptyCart } from "@/api/CartApi";
import useNotyf from "@/composable/useNotyf";
import { debounce } from "lodash";
export default defineComponent({
    name: "Layout",
    components: {
        // KTScrollTop,
        KTUserMenu: KTUserMenu,
        KTLoader: KTLoader,
        BaseVegetableNavBar: BaseVegetableNavBar,
        BaseClothNavBar: BaseClothNavBar,
        KTFooter: KTFooter,
        KTSidebar: KTSidebar,
        BaseVegetableSidebar: BaseVegetableSidebar,
    },
    setup: function () {
        var _this = this;
        var store = useStore();
        var route = useRoute();
        var showSidebar = computed(function () {
            return ["home", "category-show"].indexOf(route.name) > -1;
        });
        store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
        HtmlClass.init();
        var pageTitle = computed(function () {
            return store.getters.pageTitle;
        });
        var breadcrumbs = computed(function () {
            return store.getters.pageBreadcrumbPath;
        });
        var isHorizontalMenu = ref(true);
        var showCategoryMenu = computed(function () {
            return (!String(route.name).includes("account-") &&
                !(route.name === "store" ||
                    route.name === "checkout" ||
                    route.name === "static-ordered-page"));
        });
        var theme = ref("Vegetable");
        var navbar = computed(function () {
            return "Base" + theme.value + "NavBar";
        });
        var sidebar = computed(function () {
            return "Base" + theme.value + "Sidebar";
        });
        var loading = ref({
            clearAll: false,
            clearOneByOne: 0,
        });
        var notification = useNotyf();
        var cart = computed(function () {
            return store.getters.currentCart;
        });
        var clearAll = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loading.value.clearAll = true;
                        return [4 /*yield*/, emptyCart({})
                                .then(function (res) {
                                var _a;
                                notification.success((_a = res.data.message) !== null && _a !== void 0 ? _a : "Cart Clear!");
                            })
                                .catch(function () {
                                "_";
                            })];
                    case 1:
                        _a.sent();
                        loading.value.clearAll = false;
                        getCartList();
                        return [2 /*return*/];
                }
            });
        }); };
        var resizeScreen = debounce(function () {
            var _a;
            isHorizontalMenu.value =
                (((_a = store.getters.getStoreData) === null || _a === void 0 ? void 0 : _a.default_menu_position) === "horizontal" ||
                    screenWidth() < 1280) &&
                    ["checkout", "static-ordered-page"].indexOf(route.name) ===
                        -1;
        }, 100);
        watch(function () { return route.path; }, function () {
            MenuComponent.hideDropdowns(undefined);
            removeModalBackdrop();
            nextTick(function () {
                reinitializeComponents();
            });
        });
        onMounted(function () {
            nextTick(function () {
                reinitializeComponents();
            });
            setTimeout(function () {
                store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
            }, 0);
            resizeScreen();
            window.addEventListener("resize", resizeScreen);
        });
        return {
            toolbarDisplay: toolbarDisplay,
            loaderEnabled: loaderEnabled,
            contentWidthFluid: contentWidthFluid,
            loaderLogo: loaderLogo,
            asideEnabled: asideEnabled,
            subheaderDisplay: subheaderDisplay,
            displaySidebar: displaySidebar,
            pageTitle: pageTitle,
            breadcrumbs: breadcrumbs,
            navbar: navbar,
            sidebar: sidebar,
            clearAll: clearAll,
            loading: loading,
            cart: cart,
            route: route,
            isHorizontalMenu: isHorizontalMenu,
            showSidebar: showSidebar,
            showCategoryMenu: showCategoryMenu,
            store: store,
        };
    },
});
