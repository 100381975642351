import { __awaiter, __generator } from "tslib";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { asyncLocalStorage, getCartList, scrollUpToClass, translate, } from "@/core/helpers/custom";
import { emptyWishlist } from "@/api/WishlistApi";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n/index";
import { applyShippingMethod, shippingMethodList } from "@/api/CartApi";
import { getAddress } from "@/api/AddressApi";
import useNotyf from "@/composable/useNotyf";
import { debounce } from "lodash";
export default defineComponent({
    name: "BaseVegetableNavBar",
    components: {
    // KTSearch,
    },
    setup: function () {
        var _this = this;
        var shippingMethods = ref([]);
        var addresses = ref([]);
        var i18n = useI18n();
        var router = useRouter();
        var store = useStore();
        var route = useRoute();
        var notification = useNotyf();
        var currentShippingAddress = ref({});
        var search = ref("");
        var inputRefMobile = ref();
        var searchMenuMobile = ref();
        var t = useI18n().t;
        var storeData = computed(function () {
            return store.getters.getStoreData;
        });
        var cart = computed(function () {
            return store.getters.currentCart;
        });
        var clearAllWishlist = function () {
            Swal.fire({
                title: t("Are you sure you want to clear?"),
                showCancelButton: true,
                confirmButtonText: t("Yes"),
                cancelButtonText: t("Cancel"),
                confirmButtonColor: "#00AF27",
            }).then(function (result) {
                if (result.isConfirmed) {
                    emptyWishlist()
                        .then(function () {
                        store.commit(Mutations.REFRESH_CLEAR_WISHLIST, result.isConfirmed);
                    })
                        .catch();
                }
            });
        };
        var tr = function (text) {
            return translate(text);
        };
        var isUserAuthenticated = computed(function () {
            return store.getters.isUserAuthenticated;
        });
        var currentUserData = computed(function () {
            var _a, _b;
            return (_b = (_a = store.getters) === null || _a === void 0 ? void 0 : _a.currentUser) === null || _b === void 0 ? void 0 : _b.auth_user;
        });
        i18n.locale.value = localStorage.getItem("lang")
            ? localStorage.getItem("lang")
            : "en";
        var countries = {
            en: {
                flag: require("@/assets/svg/united-states.svg"),
                name: "English",
                lang: "en",
            },
            km: {
                flag: require("@/assets/svg/cambodia.svg"),
                name: "Khmer",
                lang: "km",
            },
        };
        var menuData = computed(function () {
            return [
                {
                    routerName: "account-settings",
                    name: "Account Setting",
                    location: "setting",
                    icon: "bi bi-gear-fill",
                },
                {
                    routerName: "account-address-listing",
                    name: "Address",
                    location: "address",
                    icon: "bi bi-geo-alt-fill",
                },
                {
                    routerName: "account-order-index",
                    name: "Order",
                    location: "order",
                    icon: "bi bi-receipt",
                },
                {
                    routerName: "store",
                    name: "Store",
                    location: "store",
                    icon: "bi bi-shop",
                },
                {
                    routerName: "account-wishlist",
                    name: "Wishlist",
                    location: "account-wishlist",
                    icon: "bi bi-heart-fill",
                },
            ];
        });
        var signOut = function () {
            Swal.fire({
                title: t("Are you sure you want to log out?"),
                showCancelButton: true,
                confirmButtonText: t("Yes"),
                cancelButtonText: t("Cancel"),
                confirmButtonColor: "#00AF27",
            }).then(function (result) { return __awaiter(_this, void 0, void 0, function () {
                var showNotification;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!result.isConfirmed) return [3 /*break*/, 3];
                            showNotification = true;
                            return [4 /*yield*/, store.dispatch(Actions.LOGOUT, showNotification).then(function () {
                                    router.push({ name: "mobile-login" });
                                })];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, asyncLocalStorage
                                    .removeItem("currentShippingAddress")
                                    .then(function () {
                                    store.commit(Mutations.SET_CURRENT_SHIPPING_ADDRESS, null);
                                })];
                        case 2:
                            _a.sent();
                            _a.label = 3;
                        case 3: return [2 /*return*/];
                    }
                });
            }); });
        };
        var setLang = function (lang) {
            localStorage.setItem("lang", lang);
            i18n.locale.value = lang;
        };
        var currentLanguage = function (lang) {
            return i18n.locale.value === lang;
        };
        var currentLangugeLocale = computed(function () {
            return countries[i18n.locale.value];
        });
        var pageTitle = computed(function () {
            return store.getters.pageTitle;
        });
        var breadcrumbs = computed(function () {
            return store.getters.pageBreadcrumbPath;
        });
        var getShippingMethod = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, shippingMethodList({})
                            .then(function (res) {
                            shippingMethods.value = res.data.data;
                        })
                            .catch(function () {
                            "_";
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var changeShippingMethod = function (shippingMethodId) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, applyShippingMethod({
                            shipping_id: shippingMethodId,
                        }).then(function (res) {
                            notification.success(
                            // res.data.message ?? "Change Shipping Method Successfully."
                            t("Change Shipping Method Successfully."));
                            getCartList();
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var setCurrentShippingAddress = function (addressId) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!addressId) return [3 /*break*/, 2];
                        return [4 /*yield*/, asyncLocalStorage
                                .getItem("currentShippingAddress")
                                .then(function (res) {
                                var _a;
                                store.commit(Mutations.SET_CURRENT_SHIPPING_ADDRESS, (_a = JSON.parse(res !== null && res !== void 0 ? res : "{}")) === null || _a === void 0 ? void 0 : _a.id);
                                currentShippingAddress.value = JSON.parse(res !== null && res !== void 0 ? res : "{}");
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, asyncLocalStorage
                            .setItem("currentShippingAddress", JSON.stringify(currentShippingAddress.value))
                            .then(function () {
                            store.commit(Mutations.SET_CURRENT_SHIPPING_ADDRESS, addressId);
                            currentShippingAddress.value = addresses.value.find(function (x) { return x.id === addressId; });
                        })];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        var getAddresses = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!isUserAuthenticated.value) return [3 /*break*/, 2];
                        return [4 /*yield*/, getAddress({})
                                .then(function (res) {
                                addresses.value = res.data.data;
                            })
                                .catch(function () {
                                "_";
                            })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        var searching = debounce(function () {
            router.push({
                name: "home",
                query: {
                    search: search.value,
                },
            });
        }, 1000);
        var openSearch = function () {
            var body = document.getElementById("kt_body");
            var sidebar = document.getElementById("kt_sidebar");
            var isSidebarOn = (body === null || body === void 0 ? void 0 : body.getAttribute("data-kt-drawer")) === "on";
            setTimeout(function () {
                var _a, _b, _c;
                var isShow = (_a = searchMenuMobile.value) === null || _a === void 0 ? void 0 : _a.classList.contains("show");
                if (!isSidebarOn) {
                    if (isShow) {
                        (_b = inputRefMobile.value) === null || _b === void 0 ? void 0 : _b.focus();
                    }
                }
                else {
                    sidebar === null || sidebar === void 0 ? void 0 : sidebar.classList.remove("drawer-on");
                    body === null || body === void 0 ? void 0 : body.removeAttribute("data-kt-drawer-sidebar");
                    body === null || body === void 0 ? void 0 : body.removeAttribute("data-kt-drawer");
                    (_c = inputRefMobile.value) === null || _c === void 0 ? void 0 : _c.focus();
                }
            }, 250);
        };
        var scrollUp = function () {
            scrollUpToClass("card_div");
        };
        watch(function () { return store.getters.isUserAuthenticated; }, function (updated) {
            if (updated) {
                getShippingMethod();
                getAddresses();
                setCurrentShippingAddress(store.getters.getCurrentShippingAddress);
            }
        });
        onMounted(function () {
            getAddresses();
            getShippingMethod();
            setCurrentShippingAddress(store.getters.getCurrentShippingAddress);
        });
        return {
            route: route,
            router: router,
            store: store,
            cart: cart,
            clearAllWishlist: clearAllWishlist,
            signOut: signOut,
            setLang: setLang,
            currentLanguage: currentLanguage,
            currentLangugeLocale: currentLangugeLocale,
            countries: countries,
            currentUserData: currentUserData,
            isUserAuthenticated: isUserAuthenticated,
            menuData: menuData,
            storeData: storeData,
            pageTitle: pageTitle,
            breadcrumbs: breadcrumbs,
            shippingMethods: shippingMethods,
            addresses: addresses,
            changeShippingMethod: changeShippingMethod,
            setCurrentShippingAddress: setCurrentShippingAddress,
            currentShippingAddress: currentShippingAddress,
            searching: searching,
            search: search,
            inputRefMobile: inputRefMobile,
            openSearch: openSearch,
            searchMenuMobile: searchMenuMobile,
            i18n: i18n,
            tr: tr,
            scrollUp: scrollUp,
        };
    },
});
