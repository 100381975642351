import { defineComponent } from "vue";
export default defineComponent({
    name: "BaseButton",
    props: {
        showLoading: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: "submit",
        },
        btnPrimary: {
            type: Boolean,
            default: false,
        },
        btnDanger: {
            type: Boolean,
            default: false,
        },
        isGreenBackground: {
            type: Boolean,
            default: true,
        },
    },
});
