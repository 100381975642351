import { __awaiter, __generator } from "tslib";
import { computed, defineComponent, onMounted, ref } from "vue";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { emptyCart, qtyAdjustment, removeProductFromCart } from "@/api/CartApi";
import useNotyf from "@/composable/useNotyf";
import router from "@/router";
import { audioPlayClick, getCartList, translate } from "@/core/helpers/custom";
import { debounce } from "lodash";
import BaseIcon from "@/components/BaseIcon.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n/index";
export default defineComponent({
    name: "sidebar",
    components: { BaseIcon: BaseIcon },
    setup: function () {
        var _this = this;
        var ktSidebar = ref(null);
        var t = useI18n().t;
        var store = useStore();
        var cart = computed(function () {
            return store.getters.currentCart;
        });
        var notification = useNotyf();
        var loading = ref({
            clearAll: false,
            clearOneByOne: 0,
            adjustQty: 0,
        });
        var setSidebarToggle = function () {
            var currentSidebarToggleState = store.getters.toggleSidebarStateValue;
            store.commit(Mutations.SET_TOGGLE_SIDEBAR, !currentSidebarToggleState);
        };
        var isUserAuthenticated = computed(function () {
            return store.getters.isUserAuthenticated;
        });
        var currentSidebarToggleState = computed(function () {
            return store.getters.toggleSidebarStateValue;
        });
        var clearAll = function () { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                Swal.fire({
                    title: t("Are you sure you want to remove everything from your cart?"),
                    showCancelButton: true,
                    confirmButtonText: t("Yes"),
                    cancelButtonText: t("Cancel"),
                    confirmButtonColor: "#00AF27",
                }).then(function (result) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!result.isConfirmed) return [3 /*break*/, 2];
                                audioPlayClick("RemoveCart");
                                loading.value.clearAll = true;
                                return [4 /*yield*/, emptyCart({})
                                        .then(function (res) {
                                        // notification.success(res.data.message ?? "Cart Clear!");
                                        notification.success(translate("Cart Clear!"));
                                    })
                                        .catch(function () {
                                        "_";
                                    })];
                            case 1:
                                _a.sent();
                                loading.value.clearAll = false;
                                getCartList();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        }); };
        var gotoCheckout = function () {
            audioPlayClick("MouseWheel");
            // if (isUserAuthenticated.value) {
            //   router.push({ name: "checkout" });
            // } else {
            //   router.push({
            //     name: "login",
            //     params: {
            //       gotoCheckout: "true",
            //     },
            //   });
            // }
            // if (isUserAuthenticated.value) {
            router.push({ name: "checkout" });
            // } else {
            //   router.push({
            //     name: "login",
            //     params: {
            //       gotoCheckout: "true",
            //     },
            //   });
            // }
        };
        var removeCartItem = debounce(function (productId, index) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        audioPlayClick("RemoveCart");
                        loading.value.clearOneByOne = index;
                        return [4 /*yield*/, removeProductFromCart({ cart_id: productId }).then(function (res) {
                                // notification.success(res.data.message ?? "Item removed!");
                                notification.success(translate("Item removed!"));
                            })];
                    case 1:
                        _a.sent();
                        getCartList();
                        loading.value.clearOneByOne = 0;
                        return [2 /*return*/];
                }
            });
        }); }, 500);
        var adjustQty = debounce(function (quantity, cartId, type, index) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loading.value.adjustQty = index + 1;
                        audioPlayClick("Mouse");
                        if (type === "minus") {
                            if (quantity > 1) {
                                --quantity;
                            }
                        }
                        else {
                            ++quantity;
                        }
                        store.commit(Mutations.TOGGLE_GLOBAL_LOADING, true);
                        return [4 /*yield*/, handleQtyAdjustment(cartId, quantity)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); }, 0);
        // async function handleQtyAdjustment(cartId: any, quantity: any) {}
        var handleQtyAdjustment = debounce(function (cartId, quantity) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, qtyAdjustment({
                            cart_id: cartId,
                            quantity: quantity,
                        }).then(function () {
                            getCartList()
                                .then(function () {
                                loading.value.adjustQty = 0;
                            })
                                .catch(function () {
                                ("");
                            });
                            store.commit(Mutations.TOGGLE_GLOBAL_LOADING, false);
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); }, 250);
        var clickBack = function () {
            audioPlayClick("Pen");
        };
        onMounted(function () {
            getCartList();
        });
        return {
            currentSidebarToggleState: currentSidebarToggleState,
            setSidebarToggle: setSidebarToggle,
            cart: cart,
            loading: loading,
            clearAll: clearAll,
            removeCartItem: removeCartItem,
            gotoCheckout: gotoCheckout,
            isUserAuthenticated: isUserAuthenticated,
            adjustQty: adjustQty,
            clickBack: clickBack,
        };
    },
});
